
import { Options, Vue } from "vue-property-decorator";
import { parseTime } from "@/utils/index";
import * as api from "@/api/safecheck";
@Options({})
export default class extends Vue {
  billType = 0;
  billTypes = [
    { text: "全部记录", value: 0 },
    { text: "检查记录单", value: 1 },
    { text: "隐患整改单", value: 2 },
    { text: "停工整改单", value: 3 },
  ];
  refreshing = false;
  loading = false;
  finished = false;
  showCalendar = false;
  selectDate: string | null = "请选择时间";
  createDate: Date | string = "";
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  safeRecordList: any[] = [];
  onRefresh() {
    this.page.pageNum = 1;
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.safeRecordList = [];
        this.refreshing = false;
      }
      let param: any = {
        projectId: this.$store.state.project.projectinfo.projectId,
        createDate: this.createDate
          ? parseTime(this.createDate, "{m}月{d}日")
          : null,
        ...this.page,
      };
      if (this.billType !== 0) {
        param.billType = this.billType;
      }
      api.mobileReportBillPageAll(param).then((result: any) => {
        if (result.code !== 0) {
          return this.$toast.fail(result.msg);
        }
        this.safeRecordList = [...this.safeRecordList, ...result.data.records];
        this.loading = false;
        if (this.safeRecordList.length >= result.data.total) {
          this.finished = true;
        }
        this.page.pageNum++;
      });
    }, 1000);
  }
  onConfirm(date: Date) {
    this.createDate = date;
    this.selectDate = parseTime(date, "{m}月{d}日");
    this.showCalendar = false;
    this.page = {
      pageSize: 10,
      pageNum: 1,
    };
    this.refreshing = true;
    this.onRefresh();
  }
  fSetTime() {
    this.selectDate = "请选择时间";
    this.showCalendar = false;
    this.page = {
      pageSize: 10,
      pageNum: 1,
    };
    this.refreshing = true;
    this.onRefresh();
  }
  fChangeType(n: number) {
    this.page = {
      pageSize: 10,
      pageNum: 1,
    };
    this.refreshing = true;
    this.onRefresh();
  }
  fGoAdd() {
    this.$router.push("/app/safeCheckSubmit");
  }
  protected goInfo(item: any) {
    this.$router.push({
      name: "safeCheckInfo",
      query: { id: item.rectifyId },
    });
  }
}
